{
  "about": "About",
  "accept": "Accept",
  "account": "Account",
  "action": "Action",
  "actions": "Actions",
  "announcement": "Announcement",
  "actionSteps": {
    "approve": {
      "loadingLabel": "Confirm approval in wallet",
      "invalidReason": {
        "title": "Approval insufficient",
        "description": "Approved amount isn't enough to cover the transaction, please try again."
      }
    },
    "swap": {
      "loadingLabel": "Confirm swap in wallet"
    }
  },
  "activity": "Activity",
  "addLiquidity": "Add liquidity",
  "addToken": "Add a token",
  "addTokens": "Add tokens",
  "alerts": {
    "allowances-fetch-error": "Failed to fetch token allowances.",
    "balances-fetch-error": "Failed to fetch token balances.",
    "pool-fetch-error": "Failed to fetch pool.",
    "pools-fetch-error": "Failed to fetch pools.",
    "price-fetch-error": "Our pricing provider, CoinGecko, is currently down.",
    "retry-label": "Refresh to try again",
    "boostedPools": "Increased yields, greater capital efficiency, deeper liquidity. Try BurrBear Boosted pools now →",
    "vebalL2": "Staking is live on Polygon and Arbitrum. Incentives for eligible pools will resume on April 28.",
    "withdrawToInternalBalance": {
      "title": "Withdraw to Vault balance",
      "description": "This transaction will withdraw your tokens from this pool to your BurrBear Vault balance. \n\nYou will then be able to withdraw the relevant tokens from the Vault using the"
    }
  },
  "allow": "Allow",
  "allowing": "Allowing...",
  "allPools": "All pools",
  "allTokens": "All tokens",
  "and": "and",
  "amount": "Amount",
  "amountBeforeFees": "Amount before fees",
  "amplification": "Amplification",
  "ampFactor": {
    "title": "Amp factor"
  },
  "analytics": "Analytics",
  "apiErrorCodeDetails": {
    "DuplicateOrder": "There was another identical order already submitted. Please try again.",
    "InsufficientFee": "The signed fee is insufficient. It's possible that is higher now due to a change in the gas price, ether price, or the sell token price. Please try again to get an updated fee quote.",
    "InvalidSignature": "The order signature is invalid. Check whether your Wallet app supports off-chain signing.",
    "MissingOrderData": "The order has missing information.",
    "InsufficientValidTo": "The order you are signing is already expired. This can happen if you set a short expiration in the settings and waited too long before signing the transaction. Please try again.",
    "InsufficientFunds": "The account doesn't have enough funds.",
    "UnsupportedToken": "One of the tokens you are swapping is unsupported.",
    "WrongOwner": "The signature is invalid.\n\nIt's likely that the signing method provided by your wallet doesn't comply with the standards required by BurrBear.\n\nCheck whether your Wallet app supports off-chain signing (EIP-712 or ETHSIGN).",
    "NotFound": "Token pair selected has insufficient liquidity.",
    "OrderNotFound": "The order you are trying to cancel does not exist.",
    "AlreadyCancelled": "Order is already cancelled.",
    "OrderFullyExecuted": "Order is already filled.",
    "OrderExpired": "Order is expired.",
    "UnsupportedSellTokenSource": "The sell token source is currently not supported.",
    "UnsupportedBuyTokenSource": "The buy token source is currently not supported.",
    "UnhandledGetError": "Order fetch failed. This may be due to a server or network connectivity issue. Please try again later.",
    "UnhandledCreateError": "The order was not accepted by the network.",
    "UnhandledDeleteError": "The order cancellation was not accepted by the network.",
    "UnhandledError": "Something failed. Please try again later.",
    "Error403Accept": "The order cannot be accepted. Your account is deny-listed.",
    "Error403Cancel": "The order cannot be cancelled. Your account is deny-listed.",
    "Error429Accept": "The order cannot be accepted. Too many order placements. Please retry in a minute.",
    "Error429Cancel": "The order cannot be cancelled. Too many order cancellations. Please retry in a minute.",
    "Error500": "Error {0} the order."
  },
  "attribute": "Attribute",
  "approve": "Approve",
  "approved": "Approved",
  "approvedCowswapRelayer": "Approved Cowswap relayer",
  "approvedLidoRelayer": "Approved Lido relayer",
  "approveCowswapRelayer": "Approve Cowswap relayer",
  "approveGP": "Approve swapping on Cowswap Protocol",
  "approveLidoRelayer": "Approve Lido relayer",
  "approveBatchRelayer": "Approve Batch relayer",
  "signRelayerApproval": "Sign relayer approval",
  "signingRelayerApproval": "Signing relayer approval",
  "signRelayerApprovalTooltip": "Your signature is required to use the relayer.",
  "approvingBatchRelayer": "Approving Batch relayer",
  "approveBatchRelayerTooltip": "Your permission is required to use the Batch relayer.",
  "approveRelayerTooltip": "Your permission is required to use the relayer.",
  "approveTokens": "Approve tokens",
  "approving": "Approving",
  "approvingCowswapRelayer": "Approving Cowswap relayer",
  "approvingLidoRelayer": "Approving Lido relayer",
  "apr": "APR",
  "apyvisionPoolInsights": "More insights on APY.vision",
  "autoOptimiseLiquidityToggle": {
    "label": "Auto optimize liquidity",
    "tooltip": "Optimise token values automatically to match the token weights you specified."
  },
  "available": "Available",
  "availableToClaim": "Available to claim:",
  "balance": "Balance",
  "walletBalance": "Wallet Balance",
  "balancerGovernance": "BurrBear governance",
  "balances": "Balances",
  "basedOnLast24h": "based on the last 24h",
  "basedOnTokensInWallet": "Based on pool tokens in your wallet",
  "best": "Best",
  "browseLists": "Browse lists",
  "browserWallet": "Browser Wallet",
  "bugBounty": "Bug bounty",
  "builtOnBalancer": "Built on BurrBear protocol",
  "boosted": "Boosted",
  "boostedTooltip": "This pool has a Boosted Pool component. In addition to accumulating swap fees, Boosted pools also generate yield for LPs by lending excess liquidity to other yield generating DeFi protocols.",
  "clpTooltip": "Designed for capital efficiency,  ‘Concentrated Liquidity Pools’ (CLPs) are a class of AMMs that price the exchange of assets within a defined range. As such, any CLP only provides liquidity for trading activity restricted to this specific region.",
  "buy": "Buy",
  "build": "Build",
  "blog": "Blog",
  "boostedPool": "BurrBear Boosted pool",
  "byConnectingWallet": "By connecting a wallet, I agree to BurrBear Foundation’s",
  "brandedRedirect": {
    "xave": {
      "title": "Xave Finance",
      "description": "Xave Finance  (pronounced \"Save Finance\") allows financial services companies (\"fintechs\") to leverage DeFi for real time remittance transfers and high yield consumer savings. Xave does this in a capital efficient way by building liquid FX markets via our custom FXPools integrated with the BurrBear V2 Vault.",
      "btnText": "Manage liquidity on app.xave.co"
    },
    "gyro": {
      "title": "Gyro Finance",
      "description": "Gyroscope is an asset-backed, redeemable stablecoin. It combines several new design elements, including a diversified reserve, a new oracle and new AMMs such as the E-CLP. The E-CLP, for example, merges the UX of StableSwap with the capital efficiency of Uniswap V3, featuring asymmetric price bounds and custom liquidity profiles.",
      "btnText": "Manage liquidity on app.gyro.finance"
    }
  },
  "cancel": "Cancel",
  "cancelling": "Cancelling",
  "change": "Change",
  "checkWallet": "Confirm in wallet",
  "changeTheme": "Change theme",
  "claim": "Claim",
  "claimHero": {
    "title": "Claim liquidity incentives",
    "legacyTitle": "Claim legacy incentives",
    "description": "BurrBear Protocol liquidity incentives are directed to pools by veBAL voters. Stake in these pools to earn incentives. Boost with veBAL for up to 2.5x extra.",
    "legacyDescription": "Liquidity mining incentive systems before the launch of ve8020-tokenomics have been deprecated. If you provided liquidity before this change and have outstanding incentives you can claim them here.",
    "tipLabel": {
      "addLiquidity": "Add liquidity",
      "stake": "Stake your LP",
      "boost": "Get a boost",
      "claim": "Claim anytime"
    },
    "tips": {
      "addLiquidity": "To earn liquidity mining incentives, add liquidity to eligible pools across Ethereum Mainnet and supported Layer 2's like Polygon and Arbitrum (see the table on the veBAL page). Note: You'll earn swap fees with or without staking but you don't need to claim these as they are automatically added to your position.",
      "stake": "If you've provided liquidity in an eligible pool, you'll have an option to stake your Liquidity Provider (LP) tokens from that pool. Stake them to receive liquidity mining incentives in addition to any swap fees.",
      "boost": "Get veBAL to boost your liquidity mining incentives by up to 2.5x, including on L2 networks via cross-chain boosts. The more veBAL you hold, the larger your boost. veBAL holders also earn protocol fees and can vote to direct future pool emissions.",
      "claim": "Token earnings from liquidity mining accumulate every block and can be claimed at any time. Some pools offer multiple token incentives (not just BAL). Additionally, veBAL holders also get a share of protocol revenue, in both bb-a-USD and BAL."
    }
  },
  "claimPage": {
    "tips": {
      "BalIncentives": "Incentives for LPs who stake in eligible pools (based on the previous week's voting). This doesn't include swap fees or intrinsic yield from certain yield bearing tokens which accumulate into LP positions automatically.",
      "ProtocolAndVebal": "Additional incentives for veBAL holders: For locking in the B-80BAL-20WETH pool (based on the previous week's voting), and their share of protocol revenue.",
      "OtherIncentives": "Incentives from third parties who want to further incentivize liquidity on top of any BAL liquidity incentives."
    }
  },
  "claimAll": "Claim all",
  "claimBAL": "Claim $BAL for",
  "claiming": "Claiming...",
  "claimSuccess": "You successfully claimed $BAL!",
  "classic": "Classic",
  "clearForms": "Clear forms",
  "clearTransactions": "Clear transactions",
  "close": "Close",
  "coingeckoPricingTooltip": "The data for this graph is pulled from our pricing provider, CoinGecko.",
  "communitySwapFeeLabel": "Delegated swap fees; currently fixed: <span class='font-semibold'>{0}</span>",
  "composition": "Composition",
  "composableStablePool": "Multi Stable Pool",
  "configuration": "Configuration",
  "confirm": "Confirm",
  "confirming": "Confirming...",
  "confirmSwap": "Confirm swap",
  "confirmUnwrap": "Confirm {0} unwrap",
  "confirmWrap": "Confirm {0} wrap",
  "connect": "Connect",
  "connecting": "Connecting...",
  "connectWallet": "Connect Wallet",
  "connectYourWallet": "Connect your wallet",
  "copied": "Copied!",
  "copperLaunchPromo": {
    "buttonLabel": "View this auction on Copper",
    "description": "Copper is the platform for FLAs—a simple crowdfunding mechanism that enables projects and ideas from across the world to raise money from individuals without barriers to entry.",
    "poweredByBalancer": "Copper is built on BurrBear V2 and uses Liquidity Bootstrapping Pools to power its FLAs",
    "title": "A Copper fair launch auction pool (FLA)"
  },
  "continueAnyway": "Continue anyway",
  "copyAddress": "Copy address",
  "correctTokenPricing": "Correct token pricing may help you avoid losing money to arbitrageurs. Losses could happen if you don't add pool assets in proportion to their target weights.",
  "create": "Create",
  "createAPool": {
    "addTokenPrice": "Add token price(s) ->",
    "alternativeFeeManagement": "Alternative fee management options",
    "arbTitle": "You could lose up to {0} ({1})",
    "arbReason": "Based on pricing information from CoinGecko, you’re vulnerable to losing funds to arbitrageurs since the amounts you’re adding to the pool are not in proportion to the pool weights specified.",
    "bestFeeOption": "0.30% is best for most generalized pools with established tokens. Go higher for more exotic tokens.",
    "chooseTokenWeights": "Choose tokens & weights",
    "customAddressOption": "Allow dynamic fees from an address I choose",
    "customAddressInfo": "This could be another address you own an address of a smart contract. You can only ever set this once.",
    "customAddressTitle": "Enter an address which can update fees",
    "dynamicFeeRadioLabel": "Allow dynamic fees from an address I choose",
    "enterTokenPrice": "Enter the estimated current price of {0} in $USD",
    "existingPoolInfo": "There’s already another pool with exactly the same tokens and fee structure. It’s recommended to add your liquidity to the other pool or to go back and change your Pool Creation parameters in a material way to avoid fractured liquidity and lower profits for both pools.",
    "fixedFeeRadioLabel": "Permanently fix fees to the initial rate",
    "governanceFees": "Allow BurrBear governance to manage fees",
    "governanceFeesTooltip": "Enable this to give BurrBear governance the ability to dynamically manage the fees of this pool in order to maximize profits. But note, BurrBear governance is under no obligation to actually manage the fees and will likely only do so if there is significant swap volume.",
    "invalidInitialWeightsInfo": "One or more tokens has an initial weight less than 1% which may cause funding to fail. If the token has a missing price, enter in a price. Otherwise, adjust the initial balances in the previous step.",
    "invalidInitialWeightsTitle": "Invalid initial weight",
    "maxInitialLiquidity": "Max initial liquidity",
    "maxLiquidityTooltip": "This is the max amount of liquidity you can provide to the pool with the current token selection.",
    "myAddressOption": "My address: {0}",
    "nativeAssetWarning": "Your ETH/WETH balance may not be sufficient for seed this pool and may cause pool funding to fail. You can adjust whether you use ETH or WETH for seed liquidity in the previous step.",
    "poolSummary": "Pool summary",
    "poolAlreadyExists": "This pool already exists",
    "previewPool": "Preview new {0} pool",
    "recommendedLiquidity": "It’s recommended to provide new pools with at least $20,000 in initial funds",
    "recoveredState": "We've recovered your previous choices",
    "recoveredStateInfo": "You previously left before creating this pool. Your choices have been saved so you can continue from before.",
    "similarPoolsExist": "Similar pools exist",
    "setAnAddress": "Set an address to control fees",
    "setPoolFees": "Set pool fees",
    "swapFeeSuggestion": "0.30% is best for most generalized pools with established tokens. Go higher for more exotic pairs.",
    "title": "Create a pool",
    "tokensAndSeedLiquidity": "Tokens and initial seed liquidity",
    "totalWeightAlert": "The total weighting of all tokens must equal exactly 100% before you can proceed.",
    "totalWeightAlertTitle": "The total weight must be 100%",
    "unknownTokenPriceAlert": "We couldn't find prices for one or more tokens you have chosen ({0}).",
    "unknownTokenPriceWarning": "Our pricing partner, CoinGecko, does not recognize {0}. This leaves you vulnerable to losing money to arbitrageurs, if you don’t add pool assets in proportion to their target weights. To be warned of potential losses, enter the current price of this asset.",
    "youCanFundWithThisPoolWith": "Based on your wallet balances for these tokens, the maximum amount you can fund this pool with is ~{0}.",
    "zeroWeightTitle": "You’ve included a token with zero weight",
    "zeroWeightInfo": "All tokens in a pool must have a weighting greater than zero. Either remove or replace {0} or set it above 0.01%."
  },
  "crossChainBoost": {
    "title": "Cross chain veBAL boosts",
    "infoDescription": "Sidechains & Layer 2 networks like Polygon and Arbitrum don’t know your veBAL balance on Ethereum Mainnet, unless you sync it. On any network where you stake, you should sync your veBAL balance to get your max possible boost. Resync after acquiring more veBAL to continue boosting to your max.",
    "sync": "Sync",
    "currentBalance": "Current balance",
    "postSyncBalance": "Post-sync balance",
    "syncedNetworks": "Synced networks",
    "syncToNetwork": "Sync veBAL to {network}",
    "syncingToNetwork": "Syncing veBAL to {network}",
    "unsyncedNetworks": "Unsynced networks",
    "unsyncedAllDescription": "Sync veBAL across networks for a boosted APR on your staked positions.",
    "syncedAllDescription": "All networks are synced",
    "emptyState": "Once you have some veBAL, sync your balance here to other networks.",
    "syncProcessWarning": {
      "title": "Wait until sync finalizes before restaking / triggering a gauge update on L2",
      "description": "Your sync has been initiated but it may take up to 30 mins to update across L2s. Once your veBAL is synced, you will need to interact with each gauge to register your new max boost. You can either claim, restake, or click the Update button, which will appear on each individual pool page staking section."
    },
    "syncComplete": {
      "title": "Remember to restake to the new boost-aware L2 pool gauges",
      "description": "To get boosted yield on L2 networks, go to your Portfolio page on the L2 network and restake from the deprecated pool gauges to the new boost-aware pool gauges."
    },
    "updateGauge": {
      "title": "Trigger pool gauge updates to get your boosts sooner",
      "description": "Pool gauges don’t automatically recognize changes in veBAL until triggered. Updates are triggered when any user interacts with a gauge, such as by claiming BAL, staking or unstaking. Trigger individual gauges yourself for your boosts to apply immediately."
    },
    "selectNetworkModal": {
      "title": "Sync veBAL: Select networks",
      "description": "Layer 2 networks don’t know your veBAL balance from Ethereum, unless you sync it. Each network costs additional gas to sync, so it’s best to only sync networks where you plan to stake."
    },
    "syncNetworkModal": {
      "title": "Sync veBAL",
      "singleNetworkTitle": "Sync veBAL to {0}",
      "description": "This will sync your veBAL balance and give you a staking boost on {0}."
    },
    "syncInitiatedModal": {
      "title": "veBAL sync initiated",
      "description": "Your veBAL balance is now being synced to the following networks:",
      "warningTitle": "Your L2 veBAL sync is in progress",
      "warningDescription": "Your sync has been initiated but it may take up to 30 mins to update across L2s. Please check the your portfolio on the destination chain/s after this time, there will be actions available to update boost on your positions. You can also update all your positions by claiming rewards on the synced chain."
    },
    "syncNetworkAction": {
      "title": "This will sync your veBAL balance and give you a staking boost across the networks listed below."
    },
    "syncInProgress": "A sync operation is currently in progress. Wait until it completes before restaking any of your positions on {network} to get your maximum veBAL boost."
  },
  "migratePool": {
    "aaveBoostedPool": {
      "whyMigrate": {
        "title": "Why migrate?",
        "description": "Boosted pools also earn additional yield from holding interest-bearing aTokens. aTokens accrue interest in real time when underlying assets are loaned out to borrowers on Aave."
      },
      "migrateToPool": {
        "title": "Migrate to a boosted pool"
      },
      "poolStats": {
        "title": "Boosted pool stats"
      }
    },
    "bbaUSDPool": {
      "whyMigrate": {
        "title": "Why migrate?",
        "description": "Boosted pools also earn additional yield from holding interest-bearing aTokens. aTokens accrue interest in real time when underlying assets are loaned out to borrowers on Aave."
      },
      "migrateToPool": {
        "title": "Migrate to a boosted pool"
      },
      "poolStats": {
        "title": "Boosted pool stats"
      }
    },
    "migrateReason": {
      "title": "Migrate to avoid vulnerability",
      "description": "A security vulnerability was recently disclosed. Although, no funds are a risk, insofar as the exploit could only be executed by pool owners or BurrBear Governance itself which is composed of known participants in the BurrBear ecosystem, it is recommended to migrate your funds asap."
    },
    "stabal3Pool": {
      "whyMigrate": {
        "title": "Why migrate?",
        "description": "Boosted pools also earn additional yield from holding interest-bearing aTokens. aTokens accrue interest in real time when underlying assets are loaned out to borrowers on Aave."
      },
      "migrateToPool": {
        "title": "Migrate to a new pool"
      },
      "poolStats": {
        "title": "New pool stats"
      }
    },
    "xmaticPool": {
      "migrateToPool": {
        "title": "Migrate your liquidity"
      },
      "poolStats": {
        "title": "Stats for the upgraded pool"
      }
    },
    "stmaticPool": {
      "migrateToPool": {
        "title": "Migrate your liquidity"
      },
      "poolStats": {
        "title": "Stats for the upgraded pool"
      }
    },
    "maiPool": {
      "migrateToPool": {
        "title": "Migrate your liquidity"
      },
      "poolStats": {
        "title": "Stats for the upgraded pool"
      }
    },
    "poolInfo": {
      "stakedUnstaked": "You have both unstaked and staked liquidity in this pool. You can migrate both or just one.",
      "stakedLabel": "Staked liquidity",
      "unstakedLabel": "Unstaked liquidity"
    },
    "approve": "Approve migration",
    "approving": "Approving migration",
    "confirming": "Confirming migration",
    "previewModal": {
      "titles": {
        "default": "Migrate preview",
        "confirmed": "Migrate confirmed"
      },
      "riskWarnings": {
        "title": "Some risks - you may lose funds in the pool if:",
        "risks": {
          "loseUSDPeg": "Any of the tokens in this pool permanently loses its target peg of 1 USD.",
          "aaveStableExploit": "Problems arise on Aave. This is because, most of the stablecoins will be invested into Aave aTokens to earn you additional yield."
        }
      },
      "summary": {
        "title": "Migrate summary",
        "stakeTitle": "Migration summary: Staked liquidity",
        "unstakeTitle": "Migration summary: Unstaked liquidity"
      },
      "actions": {
        "title": "Migrate pool",
        "loading": "Confirm pool migration in wallet",
        "migrationStep": "Confirm pool migration",
        "staked": {
          "title": "Migrate staked liquidity",
          "migrationStep": "Migrate staked liquidity"
        },
        "unstaked": {
          "title": "Migrate unstaked liquidity",
          "migrationStep": "Migrate unstaked liquidity"
        }
      },
      "priceImpactAccept": "I accept the high price impact from pool migration."
    },
    "migrateToBoostedPool": "Migrate to a boosted pool",
    "migrateLiquidity": "Migrate your liquidity",
    "errorLoadingMigration": {
      "title": "Something went wrong",
      "description": "An error occurred while trying to show pool migration."
    },
    "tooltips": {
      "totalTo": "The total value of your pool tokens in USD that you'll be migrating",
      "newTotal": "The total value in USD that you'll get into the migrated pool"
    },
    "oldVersion": "old version"
  },
  "contractAddress": "Contract address",
  "creator": "Creator",
  "owner": "Owner",
  "createDate": "Creation date",
  "creating": "Creating",
  "createPool": "Create pool",
  "createPoolTooltip": "Create {0} pool",
  "currentNetwork": "Current network",
  "custom": "Custom",
  "customAmounts": "Custom amounts",
  "customAmountsTip": "Adding custom amounts causes the internal prices of the pool to change, as if you were swapping tokens. The higher the price impact the more you'll spend in swap fees.",
  "dark": "Dark",
  "date": "Date",
  "day": "day",
  "defiLiquidityPools": "DeFi liquidity pools",
  "delegateFeesTooltip": "Liquidity providers in this pool earn fixed swap fees on every swap utilizing the liquidity in this pool. Control has been delegated to the community, so fees could change in the future if this pool becomes actively managed.",
  "depositing": "depositing",
  "details": "Details",
  "disconnect": "Disconnect",
  "dynamicSwapFeeLabel": "Dynamic swap fees: Currently <span class='font-semibold'>{0}</span>",
  "docs": "Docs",
  "earnings": "Earnings",
  "earnBAL": "Earn $BAL when swapping eligible tokens",
  "effectivePrice": "Effective price:",
  "enterAmount": "Enter amount",
  "enterAPrice": "Enter a price",
  "errorCancellingOrder": "Error cancelling order",
  "errorNoLists": "Sorry, we can't find any lists",
  "errorNoMatch": "Sorry, we can't find any matches",
  "errorNoTokens": "Sorry, we can't find any tokens",
  "ethBufferInstruction": "This pool requires Wrapped Ether (WETH). To maximize your<br>liquidity provision, you could wrap some additional ETH. Make sure to keep<br>enough ETH to cover gas costs.",
  "ethereumTxTypeTooltip": "Most users will want EIP-1559 Transactions, but there are some instances that may require a Legacy Transaction. For example, some Ledger users have had issues using MetaMask with EIP-1559 Transactions.",
  "etherscan": "Etherscan",
  "eulerLinear": "Euler Linear",
  "exceedsBalance": "Exceeds wallet balance",
  "exceedsPoolBalance": "Exceeds pool balance for this token",
  "expired": "Expired",
  "expiryDate": "Expiry date",
  "explore": "Explore",
  "exploreBalancerV1Pools": "Explore V1 pools ->",
  "explorePools": "Explore pools",
  "featuredProtocol": "Featured ecosystem protocol",
  "featuredProtocols": {
    "title": "Featured ecosystem protocols",
    "Copper": {
      "description": "Fjord (formerly, “Copper Launch”) is a community platform that connects inspired projects with passionate supporters via the power of Liquidity Bootstrapping Pools (LBPs). Also check out their new fair launch NFT drop platform.",
      "linkText": "Fjord Foundry website"
    },
    "Beets": {
      "description": "Powered by BurrBear technology, Beethoven X provides a composable liquidity base layer on Fantom and Optimism. From Boosted Pools to Maturity Adjusted fNFTs, the DEX offers a suite of powerful financial primitives.",
      "linkText": "Beethoven X website"
    },
    "Xave": {
      "description": "Xave builds liquid FX markets for DeFi. Xave's custom FXPools are integrated with BurrBear's v2 vault, providing a capital efficient liquidity hub for stablecoins and RWAs.",
      "linkText": "Xave website"
    },
    "Gyroscope": {
      "description": "Gyroscope is a new stablecoin that, like a physical gyroscope, aims to remain stable as the surrounding environment changes. Gyroscope AMMs—Concentrated Liquidity Pools (CLPs)—leverage BurrBear's customizable AMM logic.",
      "linkText": "Gyroscope website"
    }
  },
  "feedback": "feedback",
  "fees": "fees",
  "feesManagedByGauntlet": "Liquidity providers earn dynamic swap fees on every swap utilizing the liquidity in this pool. Dynamic swap fees are controlled by governance and are currently set by Gauntlet.",
  "feesTime": "Fees ({0})",
  "filter": "Filter",
  "filterByToken": "Filter by token",
  "filters": "Filters",
  "finishCreatingPoolAlert": "You last left before finishing creating and seeding a pool.",
  "finishSeedingPoolAlert": "Finish initializing the pool you just created by seeding it with liquidity",
  "fixedFeesTooltip": "Liquidity providers in this pool earn fixed swap fees on every swap that utilizes the liquidity in this pool.",
  "fixedSwapFeeLabel": "Fixed swap fees: <span class='font-semibold'>{0}</span>",
  "flashLoanFee": "Flash loan fee",
  "fundPool": "Fund pool",
  "fundPoolTooltip": "Add the initial liquidity for this pool.",
  "forum": "Forum",
  "fx": "Burr pool",
  "gyroE": "Gyro pool",
  "gaugeFilter": {
    "gaugeDisplay": "Pool gauge display",
    "showExpired": "Show expired pools",
    "moreFilters": "More filters",
    "showStake": "Show pools where I stake"
  },
  "getLpTokens": "Get LP tokens",
  "cowswapErrors": {
    "lowAmount": {
      "header": "Your swap amount is too low",
      "body": "The fees for this transaction exceed the amount you want to swap."
    },
    "lowBalance": {
      "header": "Insufficient {0} balance",
      "body": "The fees and potential slippage (up to {2}) set for this swap exceed your {0} balance. You need at least {1} {0} to proceed."
    },
    "noLiquidity": {
      "header": "No liquidity",
      "body": "Not enough liquidity"
    },
    "genericError": {
      "header": "An error has occurred"
    }
  },
  "cowswapWarnings": {
    "highFees": {
      "body": "You can still proceed with the swap but a high percentage of it will be consumed by swap fees.",
      "header": "The fees on this transaction exceed 20% of the swap amount."
    }
  },
  "goToBalancerV1Site": "Go to the BurrBear V1 site ->",
  "goToMigratedPool": "Go to migrated pool",
  "governance": "Governance",
  "grants": "Grants",
  "deprecatedPool": {
    "tooltip": "This pool has been deprecated. If you're a Liquidity Provider, please migrate to the new upgraded pool.",
    "hasNewPool": {
      "title": "Move your liquidity for future BAL incentives",
      "description": "Liquidity mining incentives are being phased out for this pool, since the upgraded pool is now live. LPs are encouraged to unstake existing positions, withdraw their liquidity and add it to the newly incentivized pool."
    },
    "generic": {
      "title": "Move your liquidity for future BAL incentives",
      "description": "Liquidity mining incentives are being phased out for this pool, since upgraded pools are now live. LPs are encouraged to unstake existing positions, withdraw their liquidity and add it to the newly incentivized pools."
    },
    "gaugeKilledReason": "This pool's gauge will soon be killed. A new version of this pool has been created that uses Aave v3."
  },
  "newVersion": {
    "Aave3": "A new version of this pool has been created that uses Aave v3",
    "BalIncentives": "A new version of this pool has been created. Please migrate to continue earning BAL incentives"
  },
  "highPriceImpact": "High price impact",
  "highPriceImpactDetailed": "This swap is significantly moving the market price.",
  "highRiskPool": "This is a higher-risk pool that contains one or more unlisted tokens. A liquidity pool is only as good as its weakest token! If a token were blacklisted, infinitely minted, frozen, or exploited in any other way, the value of this pool could go to 0. BurrBear Foundation is never liable for losses incurred through using our UI or the BurrBear protocol. Be careful, and do your own research!",
  "home": "Home",
  "hourAbbrev": "h",
  "howDidWeDo": "How did we do?",
  "incentives": "incentives",
  "incentiveToken": "Incentive token",
  "incentivizedPool": "incentivized pool",
  "inclXInVeBal": "{0} in veBAL",
  "initialBPT": "Initial BPT",
  "initialWeight": "Initial weight",
  "insufficientBalance": "Not enough funds",
  "insufficientBalanceDetailed": "This swap requires more funds than you have in your wallet.",
  "insufficientData": "Not enough data.",
  "insufficientLiquidity": "Not enough liquidity",
  "insufficientLiquidityDetailed": "Try swapping with a smaller amount or check back when liquidity for this pool has increased.",
  "invalidFee": "Invalid fee",
  "invalidFeeExplain": "Please enter a valid fee between 0.0001% and 10%",
  "investment": {
    "tabs": {
      "poolTokens": "Pool tokens",
      "singleToken": "Single token"
    },
    "preview": {
      "loadingLabel": {
        "approval": "Confirm approval in wallet",
        "create": "Confirm create in wallet",
        "fund": "Confirm funding in wallet",
        "investment": "Confirm add liquidity in wallet"
      },
      "titles": {
        "tokenIn": "You’re providing",
        "tokenOut": "You’re expected to receive",
        "confirmed": "Add liquidity confirmed",
        "default": "Add liquidity preview"
      },
      "summary": {
        "total": "Total",
        "confirmed": "Add liquidity confirmed",
        "default": "Add liquidity preview"
      },
      "tooltips": {
        "approval": "You must approve {0} to add liquidity for this token on BurrBear. Approvals are required once per token, per wallet."
      }
    },
    "warning": {
      "noBalanceSomeTokens": "No wallet balance for some pool tokens",
      "managedPoolSwappingHalted": {
        "description": "A swapping halt has been issued by the manager of this pool. For your safety, while the swapping halt is in effect, you may only add liquidity in proportional amounts.",
        "title": "Swapping halted - proportional liquidity provision only"
      },
      "lowLiquidity": {
        "description": "Add assets proportionally to the pool weights or the price impact will be high and you will likely get REKT and lose money.",
        "title": "Be careful: This pool has low liquidity"
      },
      "noPoolTokensToJoinWith": {
        "title": "You have no pool tokens to join with",
        "paragraph1": "This option would usually allow you to add pool tokens in any combination or proportionally to reduce price impact.",
        "paragraph2": "However, since your connected wallet doesn’t have any pool tokens, go to the ‘Single token’ tab above to add liquidity to this pool with non-pool tokens."
      },
      "blockedPool": {
        "title": "This pool contains one or more tokens that have not been vetted by the BurrBear community: {0}.",
        "description": "For the protection of LPs, adding liquidity to the pool has been disabled. Click",
        "description2": "to learn how to submit the token(s) for review."
      }
    },
    "error": {
      "rektPriceImpact": {
        "description": "The likelihood of you losing money is too high. For your protection, you can’t perform this transaction on this interface.",
        "title": "This price impact is too high. You cannot proceed"
      }
    }
  },
  "investmentPool": "Liquidity pool",
  "investmentPools": "Liquidity pools",
  "investmentPoolsAbout": "Stake your assets to get $BAL tokens for participating in BurrBear V2 liquidity mining.",
  "investmentPoolsAboutLink": "Learn more ->",
  "investmentSettled": "Your liquidity add has settled",
  "investmentSuccess": "Your tokens have been added to this pool, and you have received a new LP token representing this action.",
  "investmentTooltip": "Confirm your liquidity add into this pool",
  "inYourWallet": "In your wallet",
  "isRequired": "is required",
  "joinPool": "Join pool",
  "language": "Language",
  "learnMore": "Learn more",
  "lockToGetVeBAL": "Lock this to get veBAL",
  "light": "Light",
  "liquidityBootstrappingPool": "Liquidity Bootstrapping Pool",
  "liquidityIncentives": "liquidity incentives",
  "liquidityMining": "Liquidity mining",
  "liquidityMiningAPR": "Liquidity Mining APR",
  "liquidityMiningIncentives": "Liquidity mining incentives",
  "liquidityMiningIncentivesCopy": "Liquidity incentives on {0} are paused for 1 week during its upgrade to the veBAL system. Incentives will resume on April 28.",
  "liquidityMiningPopover": {
    "airdropEligibility": "People who provide liquidity to eligible pools or swap on eligible token pairs receive weekly $BAL distributions as incentives. $BAL token holders own the BurrBear Protocol and can help shape its future by voting on governance proposals.",
    "airdropExplainer": "Weekly distributions from liquidity mining on {0} are airdropped on Wednesdays, sent directly to your wallet using disperse.app",
    "claimFreezeWarning": {
      "description": "Since you have several weeks' worth of tokens unclaimed, your screen may appear to freeze after pressing the claim button while it is processed. Just leave your browser open and your wallet will eventually pop open for you to complete the claim.",
      "title": "Too many claims"
    },
    "networksExplainer": "Switch networks in the header to see your current cumulative $BAL incentives on {0} and {1}.",
    "noRewards": {
      "description": "Certain eligible pools have ‘liquidity mining’ token incentives which are distributed to liquidity providers weekly in proportion to their contribution.",
      "title": "You have no token incentives to claim"
    },
    "tabs": {
      "claimable": "Claimable"
    },
    "title": "Token incentives"
  },
  "liquidityProviderCopy": "People who provide liquidity to eligible pools or swap on eligible token pairs receive weekly $BAL distributions as incentives. $BAL token holders own the BurrBear Protocol and can help shape its future by voting on governance proposals.",
  "liquidityProvision": "Liquidity provision",
  "loading": "Loading...",
  "loadingBestPrice": "Looking for the best price...",
  "loadMore": "Load more",
  "lock": "Lock",
  "locked": "Locked",
  "locking": {
    "expiredLockLpTokens": "Expired lock LP tokens",
    "expiredLockTooltip": "Your previous locking period has expired, so you no longer have any veBAL or voting power. You will no longer earn protocol fees, boosted yields on staked Ethereum Mainnet pools or veBAL incentives. However, you'll still get the minimum 1.0x rate. Redeem your expired locked B-80BAL-20WETH LP tokens and then create a new lock to get veBAL.",
    "lockBptForVeBal": "Lock LP for veBAL",
    "lockedLpTokensTooltip": "The USD value of your veBAL. These are your locked LP tokens in the BAL 80% / WETH 20% pool.",
    "unlockedLpTokensTooltip": "The USD value of your liquidity provided to the BAL 80% / WETH 20% pool that has not been locked."
  },
  "logOut": "Log out",
  "lost": "Lost",
  "lpTokens": "LP tokens",
  "lbpAprTooltip": "Only the LBP creator can add liquidity and receive this APR.",
  "managedPool": "Managed pool",
  "manageLists": "Manage lists",
  "manageVaultBalances": "Manage Vault balances",
  "marketConditionsWarning": "Market conditions may change between the time your order is submitted and the time it gets executed on Ethereum. Slippage tolerance is the maximum change in price you are willing to accept. This protects you from front-running bots and miner extractable value (MEV).",
  "max": "Max",
  "maxed": "Maxed",
  "maximum": "Maximum",
  "minimumTotalApr": "Minimum total APR",
  "minTransactionBuffer": "To ensure a smooth transaction, at least {0} {1} must be left in your wallet to pay for gas fees.",
  "missingPoolNameOrSymbol": "Missing pool name or symbol",
  "missingTokenPrices": "Missing token prices",
  "missingPoolNameOrSymbolInfo": "There is a missing pool name or symbol, fill it in before creating the pool.",
  "menu": "Menu",
  "migrate": "Migrate",
  "migrateCard": {
    "title": "Migrate liquidity",
    "description": "Liquidity mining incentives have expired for this pool. For future BAL incentives: unstake, withdraw and add liquidity to an incentivized pool.",
    "viewBtn": "View incentivized pools",
    "recommendedPool": "Recommended incentivized pools",
    "upgradedPool": "Upgraded incentivized pool"
  },
  "modals": {
    "veBalRedirectModal": {
      "title": "Proceed to mainnet",
      "description": "Getting and using veBAL to vest and vote must be performed on the Ethereum mainnet. \n\n You can still earn liquidity mining incentives on Arbitrum, Polygon and Gnosis by adding liquidity to eligible pools. However to vote to direct emissions to your favored pools and to earn the protocol fees, you will need to use veBAL on Ethereum Mainnet by locking B-80BAL-20WETH."
    }
  },
  "month": "month",
  "months": "months",
  "mustBeAtLeast": "must be at least {0} characters",
  "mustBeInt": "must be an integer",
  "mustBeLessThan": "must be less than {0}",
  "mustBeMoreThan": "must be greater than {0}",
  "mustBePositive": "must be a positive number",
  "mustBeSame": "must be the same as {0}",
  "mustBeValidEmail": "must be a valid email",
  "mustBeValidNum": "must be a valid number",
  "mustBeValidAddress": "This is not a valid Ethereum address",
  "myApr": "My APR",
  "myBalance": "My balance",
  "myBalancerBalance": "My BurrBear balance",
  "myBoost": "My boost",
  "myPortfolio": "My portfolio",
  "myShare": "My share",
  "myLiquidityInBalancerPools": "My liquidity in BurrBear pools",
  "myWallet": "My Ethereum wallet",
  "myWallet2": "My wallet",
  "name": "Name",
  "namePlaceholder": "Name, symbol or contract address",
  "nativeTokens": "Native tokens",
  "network": "Network",
  "networkMismatch": "Please switch to {0}",
  "networkSelection": "Network selection",
  "poolDoesntExist": "Pool doesn't exist on",
  "new": "New",
  "newTotal": "New total",
  "newToEthereum": "New to Ethereum?",
  "newWithdrawal": "New withdrawal",
  "next": "Next",
  "noContent": "No content",
  "noClaimableIncentives": "No claimable incentives",
  "noClaimableIncentivesOnThisChain": "No incentives have currently been added to this chain. Check back later.",
  "noData": "No data available",
  "noInitLiquidity": "This pool has no initial liquidity",
  "noInitLiquidityDetail": "Until the pool creation UI is ready, an INIT join type is required at the smart contract before liquidity provision is supported.",
  "requiresAllowListing1": "This pool has not been added to our allowlist.",
  "requiresAllowListing2": "to learn how to enable a new pool.",
  "Click": "Click",
  "here": "here",
  "noInvestments": "No liquidity",
  "noUnstakedInvestments": "You have no unstaked liquidity on {0}",
  "noStakedInvestments": "You have no staked liquidity on {0}",
  "noNativeCurrency": "You’ll need some {0} for gas fees in order to transact on {1}.",
  "noNativeCurrencyEthereum": "You’ll need some {0} for gas fees in order to do most basic transactions on {1}. However, you may continue to swap any tokens you’ve previously approved for use on BurrBear with this wallet, by using the ‘swap gasless’ option.",
  "noPoolsFound": "No pools found",
  "noPriceImpact": "Best price",
  "noPriceImpactTip": "Adding tokens proportionally gets you the best price.",
  "noPriceInfo": "Price information is missing for this pool, since it contains a token not found by our price provider.",
  "hasNonApprovedRateProviders": "One or more token rate providers associated with tokens in this pool have not been vetted.",
  "noRecentActivity": "Your recent activity will appear here…",
  "noResults": "No results",
  "noResultsTable": {
    "noBalIncentives": "No BAL incentives to claim from staking",
    "noProtocolRevenue": "Nothing to claim from holding veBAL"
  },
  "noSwapsPool": "No swaps in this pool.",
  "noTokensInWallet": "You don't have any tokens in your connected wallet on {0}",
  "numberOfPools": "Number of pools",
  "notAvailable": "N/A",
  "oneDay": "1D",
  "oneMonth": "1M",
  "oneWeek": "1W",
  "optimize": "Optimize",
  "optimized": "Optimized",
  "optimizedPrefilled": "Optimized amounts have been pre-filled",
  "oops": "Oops",
  "oracleWeightedPool": "Oracle Generalized Pool",
  "otherTokenIncentives": "Other token incentives",
  "overview": "Overview",
  "ownerFeesTooltip": "Liquidity providers earn dynamic swap fees on every swap that utilizes the liquidity in this pool. Dynamic swap fees are controlled by the Pool Owner.",
  "payload": "Payload",
  "pages": {
    "claim": {
      "title": "Claim your liquidity mining incentives",
      "description": "Incentives to attract liquidity are set by the BurrBear protocol and partners. Stakers in eligible pools can claim incentives every block. People with veBAL get a boosted rate—up to 2.5x extra.",
      "titles": {
        "incentivesOnOtherNetworks": "Incentives on other networks",
        "legacyIncentives": "Legacy incentives"
      },
      "btns": {
        "claimOn": "Claim on"
      }
    },
    "balances": {
      "title": "My Vault balances",
      "eulerHack": "Euler eTokens (e.g. eUSDC, eUSDT and eDAI) affected by the Euler hack have been paused and are not transferrable from the BurrBear Vault. To redeem your share of the recovered funds of the Euler hack, if you haven't done so already, go to:"
    }
  },
  "pastEarnings": {
    "title": "Past earnings",
    "description": "This contract was deprecated on 25 July 2022. Any earnings after this date will be distributed by the upgraded contract."
  },
  "pendingClaims": "Pending claims",
  "pendingEstimate": "Pending: (estimate)",
  "perWeek": "per week",
  "policies": {
    "balancerThirdPartyInfo": "BurrBear is an open source, permissionless, decentralized protocol. The smart contracts that power the ecosystem may be used by anyone. This website is the BurrBear Foundation's front-end to the ecosystem",
    "cookiesPolicy": "Cookies Policy",
    "privacyPolicy": "Privacy Policy",
    "risks": "Risks",
    "termsOfUse": "Terms of Use",
    "thirdPartyServices": "3rd party services",
    "usesFollowing": "This website uses the following"
  },
  "pool": "Pool",
  "pools": "Pools",
  "poolActions": {
    "poke": "Poke to boost APR",
    "stake": "Stake for incentives",
    "unstake": "Unstake",
    "add": "Add liquidity",
    "remove": "Remove liquidity",
    "vote": "Vote for incentives",
    "migrateGauge": "Migrate pool gauge"
  },
  "poolBalance": "Pool balance",
  "poolComposition": {
    "title": "Pool composition",
    "token%": "Token %",
    "tabs": {
      "totalComposition": "Total composition",
      "myPoolShare": "My pool share"
    }
  },
  "poolCreated": "Pool created!",
  "poolName": "Pool name",
  "poolPercent": "Pool %",
  "poolReturns": "Pool returns",
  "poolSymbol": "Pool symbol",
  "poolsToMigrate": "Pools to migrate",
  "poolsToStake": "Pools to stake",
  "poolTokenName": "Pool token name",
  "poolTransactions": {
    "title": "Pool transactions",
    "tabs": {
      "allTransactions": "Transactions",
      "myTransactions": "My transactions",
      "allInvestments": "All liquidity provision",
      "myInvestments": "My liquidity",
      "swaps": "Swaps"
    },
    "noResults": {
      "allTransactions": "No transactions in this pool",
      "myTransactions": "You haven't made any transactions in this pool",
      "allInvestments": "No liquidity transactions in this pool",
      "myInvestments": "You haven't added or removed any liquidity in this pool",
      "swaps": "No swaps in this pool"
    }
  },
  "poolTransfer": {
    "myPoolBalancesCard": {
      "title": "My pool balance",
      "altTitle": "My pool liquidity balance",
      "poolsRisks": "pool's risks",
      "risksDisclaimer": "Liquidity Providers encounter risks when using DeFi and BurrBear pools. Before proceeding, view this",
      "underlyingTokens": "Underlying pool tokens"
    },
    "myWalletTokensCard": {
      "title": "Pool tokens in my wallet",
      "tooltips": {
        "nativeAssetSelection": "Select {0} or {1} to add liquidity to this pool. Wrap or unwrap {0} to maximize your liquidity provision."
      }
    }
  },
  "poolTransactionStats": {
    "swap": {
      "volume": "Volume {period}",
      "largest": "Largest swap {period}",
      "ave": "Ave. swap {period}",
      "total": "Total swaps {period}"
    },
    "investment": {
      "netTvl": "Net TVL {period}",
      "largest": "Largest add {period}",
      "ave": "Ave. liquidity add {period}",
      "net": "Net liquidity adds {period}"
    }
  },
  "poolType": "Pool type",
  "poolValue": "Pool value",
  "poolManager": "Pool Manager",
  "poolManagement": "Pool management",
  "poolDetails": "Pool details",
  "poolOwner": "Pool Owner",
  "delegateOwner": {
    "title": "Delegate owner",
    "tooltip": "Permissioned functions validated by the BurrBear Vault Authorizer"
  },
  "noOwner": "No owner",
  "poolWarnings": {
    "bugWarning20220513": {
      "title": "This pool contains double-entrypoint tokens, which have been found to be incompatible with BurrBear. It is currently not possible to withdraw all assets from the pool proportionally. While it is possible to withdraw in a single asset, liquidity providers are advised to wait. A mechanism is being implemented to allow for proportional withdrawals. <a href=\"https://forum.burrbear.io/t/medium-severity-bug-found/3161\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Learn more </a>"
    },
    "poolOwnerVulnWarningGovernance": {
      "title": "This pool is affected by a vulnerability disclosed <a href=\"https://forum.burrbear.io/t/vulnerability-disclosure/3179\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">here</a>. Reach out on Discord if you need help creating a new pool with the same composition.",
      "description": ""
    },
    "poolOwnerVulnWarningGovernanceMigrate": {
      "title": "This pool is affected by a vulnerability disclosed <a href=\"https://forum.burrbear.io/t/vulnerability-disclosure/3179\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">here</a>. You're advised to migrate your liquidity.",
      "description": ""
    },
    "poolOwnerVulnWarningGovernanceWithdraw": {
      "title": "This pool was deprecated due to a <a href=\"https://forum.burrbear.io/t/vulnerability-disclosure/3179\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">vulnerability</a>. You're advised to withdraw your liquidity asap. Add it to the <a href=\"https://app.burrbear.io/#/ethereum/pool/0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">upgraded pool</a> to participate in liquidity incentives.",
      "description": ""
    },
    "poolOwnerVulnWarningEcosystem": {
      "title": "This pool is affected by a vulnerability disclosed <a href=\"https://forum.burrbear.io/t/vulnerability-disclosure/3179\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">here</a>. Reach out on Discord if you need help creating a new pool with the same composition."
    },
    "poolOwnerVulnWarningEcosystemMigrate": {
      "title": "This pool is affected by a vulnerability disclosed <a href=\"https://forum.burrbear.io/t/vulnerability-disclosure/3179\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">here</a>. You're advised to migrate your liquidity."
    },
    "renBTCWarning": {
      "title": "You are strongly advised to withdraw any liquidity from this pool ASAP to avoid losses! Ideally you should withdraw in a proportional fashion to reduce price impact. The Ren 1.0 network is expected to be <a href=\"https://medium.com/renproject/moving-on-from-alameda-da62a823ce93\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">sunset</a> in December 2022. This will render this version of the renBTC token as valueless, which will trend the overall pool value towards zero. Ren Protocol also strongly advises to <a href=\"https://twitter.com/renprotocol/status/1595807696296751104\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">bridge all ren assets</a> back to their native chain ASAP to avoid being left holding a valueless token which can no longer be redeemed for the native asset."
    },
    "eulerBoostedWarning": {
      "title": "Due to an exploit on Euler, this pool has been set to recovery mode by the <a href=\"https://docs.burrbear.io/concepts/governance/emergency.html\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Emergency multisig</a>. Euler has disabled the transferability of eTokens so withdrawals from this pool are not possible until that functionality is restored."
    },
    "eulerRecoveryModeWarning": {
      "title": "Due to an exploit on Euler this pool has been set to recovery mode by the <a href=\"https://docs.burrbear.io/concepts/governance/emergency.html\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Emergency multisig</a>. Proportional withdrawals are enabled in the UI and you are encouraged to withdraw as soon as possible."
    },
    "poolProtocolFeeVulnWarning": {
      "title": "A vulnerability has been discovered that affects this pool. Existing liquidity providers should remove liquidity immediately. <a href=\"https://twitter.com/BurrBear/status/1611363559685898247\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Read more</a>",
      "tooltip": "A vulnerability has been discovered that affects this pool. Existing liquidity providers should remove liquidity immediately."
    },
    "cspPoolVulnWarning": {
      "title": "A vulnerability has been discovered that affects this pool. Existing liquidity providers should remove liquidity immediately. <a href=\"https://forum.burrbear.io/t/vulnerability-found-in-some-pools/5102/1\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Read more</a>",
      "tooltip": "A vulnerability has been discovered that affects this pool. Existing liquidity providers should remove liquidity immediately.",
      "generalTitle": "A vulnerability has been discovered that affects a number of pools. Existing liquidity providers should remove liquidity immediately."
    },
    "fxPoolVulnWarning": {
      "title": "Xave's FXPools are potentially affected by a bug. Xave recommends that LPs temporarily remove liquidity from this pool. <a href=\"https://twitter.com/XaveFinance/status/1725089131330756628\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Read more</a>",
      "tooltip": "Xave's FXPools are potentially affected by a bug. Xave recommends that LPs tA vulnerability has been discovered that affects this pool. Existing liquidity providers should remove liquidity immediately. <a href=\"https://twitter.com/BurrBear/status/1611363559685898247\" target=\"blank\" rel=\"noreferrer\" class=\"underline\">Read more</a>emporarily remove liquidity from this pool"
    },
    "rateProviderWarning": {
      "title": "A low severity rate provider issue has been discovered that affects this pool. Existing liquidity providers should remove liquidity as soon as possible.",
      "tooltip": "A low severity rate provider issue has been discovered that affects this pool. Existing liquidity providers should remove liquidity as soon as possible."
    }
  },
  "popularBases": "Popular",
  "portfolio": "Portfolio",
  "potential": "Potential",
  "potentialWeeklyYield": "Potential weekly yield",
  "preview": "Preview",
  "previewPool": "Preview new {0} pool",
  "previewSwap": "Preview swap",
  "previewSwapTransactions": "Preview swap transactions",
  "previewUnwrap": "Preview {0} Unwrap",
  "previewWrap": "Preview {0} Wrap",
  "previewMigrate": "Preview migrate",
  "priceImpact": "Price impact",
  "priceImpactAccept": "I accept the high price impact from {0}, moving the market price based on the depth of the market.",
  "priceImpactCheckbox": "Price impact acknowledgement",
  "priceImpactTolerance": "Price impact tolerance",
  "priceUpdatedAlert": {
    "actionLabel": "Accept",
    "description": "The swap price has updated by more than {0}",
    "title": "Price updated"
  },
  "proceed": "Proceed",
  "profit": "Profit (1{0})",
  "proportionalSuggestion": "Proportional suggestion",
  "proportionalWithdrawal": "Proportional withdrawal",
  "protocolFees": "Protocol fees",
  "protocolIncentives": "Protocol revenue & veBAL incentives",
  "rank": "Rank (1{0})",
  "receipt": "Receipt",
  "receive": "Receive",
  "recentActivityTitle": "Recent activity",
  "recoveryMode": "Recovery mode",
  "recoveryModeDescription": "This pool is paused and in recovery mode. It does not pay protocol fees and the only available exit is proportional.",
  "redeem": "Redeem",
  "requiresTransactions": "Requires 1 transaction | Requires {txCount} transactions",
  "restoring": "Restoring...",
  "returnToPool": "Return to pool page",
  "returnToPoolCreation": "Return to pool creation",
  "saveGas": " to save gas.",
  "searchBy": "Search by name, symbol or address",
  "searchByName": "Search by Name",
  "seeMore": "See more",
  "seeOnExplorer": "See on explorer",
  "seePools": "See pools",
  "seeV1BalancerInvestments": "Want to see your funds on BurrBear V1?",
  "select": "Select",
  "selectToken": "Select token",
  "sell": "Sell",
  "send": "Send",
  "services": {
    "infura": "Used to fetch on-chain data and constructs contract calls with an Infura API.",
    "alchemy": "Used to fetch on-chain data and constructs contract calls with an Alchemy API.",
    "the-graph": "Used to fetch blockchain data from The Graph’s hosted service.",
    "goldsky": "Used to fetch blockchain data from Goldsky’s subgraph service.",
    "fathom-analytics": "Used to understand user behavior on the site and marketing performance.",
    "TRM-labs": "Used to securely collect wallet addresses and shares it with TRM Labs Inc. for risk and compliance reasons.",
    "sentry": "Used for error tracking and performance monitoring.",
    "appzi": "Used to capture and store user feedback from optional surveys",
    "amazon-web-services": "Used for a variety of infrastructure services, but primarily to fetch and cache blockchain data."
  },
  "settings": "Settings",
  "setUpEthereumWallet": "BurrBear is a DeFi app on Ethereum. Set up an Ethereum Wallet to swap and provide liquidity here.",
  "singleToken": "Single token",
  "singleTokenMax": "Single token max",
  "slippageTolerance": "Slippage tolerance",
  "somethingWentWrong": "Something went wrong",
  "stablePool": "Stable pool",
  "stake": "Stake",
  "staked": "Staked",
  "staking": {
    "minimumStakingApr": "Min staking APR",
    "newTotalShare": "Your total share",
    "potentialWeeklyEarningTooltip": "The approximate weekly earning based on your staked balance",
    "stakedPools": "Staked pools",
    "stakedLpTokensTooltip": "The fiat value of LP tokens you have staked in this pool.",
    "stakeValueTooltip": "The approximate value of the LP tokens you will stake.",
    "staking": "Staking",
    "stakeTooltip": "Confirm staking of LP tokens to earn liquidity mining incentives on this pool",
    "stakingApr": "Staking APR",
    "stakingAprTooltip": "Your potential staking APR",
    "stakingIncentives": "Staking incentives",
    "totalShareTooltip": "Your current total share of LP tokens in the pool",
    "restakeGauge": "Restake to receive future liquidity mining incentives",
    "restakeGaugeDescription": "You are staking in a gauge which has been deprecated. Restake to the new gauge for this pool to receive future liquidity mining incentives.",
    "unclaimedIncentives": "Unclaimed incentives",
    "unstakedLpTokensTooltip": "The fiat value of LP tokens you can stake.",
    "unstakedPools": "Unstaked pools",
    "unstakeValueTooltip": "The approximate value of the LP tokens you will unstake.",
    "unstaking": "Unstaking",
    "restaking": "Restaking",
    "unstakeTooltip": "Confirm unstaking of LP tokens. You'll lose eligibility to earn liquidity mining incentives for this pool.",
    "restakeTooltip": "Confirm restaking of LP tokens. You'll be again able to earn liquidity mining incentives for this pool.",
    "weeklyEarning": "weekly earning"
  },
  "stakeToGetExtra": "Stake this to earn extra",
  "strategy": "Strategy",
  "submit": "Submit",
  "summary": "Summary",
  "swap": "Swap",
  "swapDetails": "Swap details",
  "swapFee": "Swap fee",
  "swapFees": "Swap fees",
  "swapFeeAPR": "Swap fees APR",
  "swapFeeManager": "Swap fee manager",
  "swapper": "Swapper",
  "swapRoute": "Swap route",
  "swapException": "Failed to submit ({0}) swap transaction.",
  "switchNetwork": "Switch network",
  "symbol": "Symbol",
  "veBAL": {
    "hero": {
      "title": "Extra earnings & voting power",
      "benefits": {
        "earn": "Earn your share of protocol revenue",
        "boost": "Boost liquidity mining yield up to 2.5x",
        "vote": "Vote to direct liquidity mining emissions"
      },
      "tokens": {
        "balWETH": "BAL + WETH",
        "lpToken": "B-80BAL-20WETH",
        "veBAL": "veBAL"
      },
      "tokenInfo": {
        "balWETH": "Add liquidity to the 80% BAL / 20% WETH pool to get the B-80BAL-20WETH LP token.",
        "lpToken": "Lock up the LP tokens from the 80% BAL / 20% WETH pool for up to a year to get veBAL. The longer you lock, the more veBAL you get.",
        "veBAL": "Use veBAL to earn extra on your staked pools. Only some pools are eligible for staking. Check any pools that you are in, and stake it to earn liquidity mining incentives. You can also use veBAL to vote to direct future liquidity mining emissions"
      },
      "buttons": {
        "getVeBAL": "Get veBAL",
        "learnMore": "Learn more"
      }
    },
    "myVeBAL": {
      "title": "Manage your veBAL",
      "cards": {
        "myLpToken": {
          "label": "My B-80BAL-20WETH"
        },
        "myLockedLpToken": {
          "label": "My locked B-80BAL-20WETH"
        },
        "lockedEndDate": {
          "label": "Locked until",
          "secondaryText": "{0} days"
        },
        "myVeBAL": {
          "label": "My veBAL",
          "secondaryText": "{0} of all veBAL"
        },
        "myExpiredLockTooltip": "Your previous lockup period has expired, so you no longer have any veBAL or voting power. You will no longer earn protocol fees, boosted yields on staked Ethereum Mainnet pools or veBAL incentives. However, you’ll still get the minimum 1.0x rate. Tap the minus icon to redeem your expired locked B-80BAL-20WETH LP tokens and then create a new lock to get veBAL."
      }
    },
    "communityVeBAL": {
      "title": "Community veBAL",
      "cards": {
        "totalVeBAL": "Total veBAL",
        "avgLockUpPeriod": "Ave. lock-up period",
        "effectiveBalLocked": "Effective BAL locked",
        "shareOfBalLocked": "Share of BAL locked"
      },
      "stats": {
        "currentWeekVoting": "Current week voting",
        "nextWeekVoting": "Next week voting",
        "balIncentives": "BAL incentives"
      },
      "table": {
        "poolGauge": "Pool gauge",
        "vote": "Vote",
        "viewMore": "View more"
      }
    },
    "liquidityMining": {
      "title": "Pools eligible for liquidity mining",
      "description": "Liquidity incentives are directed by the community of veBAL holders. If you hold veBAL, vote below on any pools across Ethereum and Layer 2’s. Your vote will persist until you change it and editing a pool can only be done once in 10 days.",
      "myUnallocatedVotes": "My unallocated votes:",
      "myUnallocatedVotesTooltip": "veBAL holders can vote for a single pool or multiple pools in whatever weights they choose, up to their max of 100%. Note: If you’ve previously voted for a pool but no longer have any veBAL, your votes will not count.",
      "unallocatedVotes": "My unallocated votes: {0}.",
      "votingPeriod": "Voting period ends: {0}d {1}h {2}m {3}s",
      "votingPeriodTooltip": "There is a weekly vote for veBAL holders which ends at 0:00 UTC on Thursdays. This vote determines the BAL incentives allocation for all Stakers in these pools for the next week. Although you don’t have to vote to get a boosted yield, your vote can help you earn more by voting on pools where you are a staker.",
      "votingPeriodCountdown": "{0}d : {1}h : {2}m : {3}s",
      "votingPowerExpiredTooltip": "Your previous lock period has expired, so you no longer have any veBAL or voting power. Any votes that have been allocated in the past will no longer count. Lock up B-80BAL-20WETH LP tokens to get veBAL for higher yields and voting power.",
      "table": {
        "chain": "Chain",
        "assets": "Assets",
        "composition": "Composition",
        "nextPeriodVotes": "Next period votes",
        "myVotes": "My votes",
        "vote": "Vote",
        "voteSelect": "Vote select",
        "remove": "Remove"
      },
      "votesTooltip": {
        "title": "Voting breakdown",
        "thisPeriod": "This period: {0}",
        "nextPeriod": "Next period: {0}"
      },
      "limitsTooltip": {
        "distributionsCappedVeBAL": "Distributions to the veBAL gauge are capped at 10%",
        "distributionsCappedAt": "Distributions to this gauge are capped at {0}%"
      },
      "popover": {
        "title": {
          "vote": "Gauge vote",
          "edit": "Edit gauge vote",
          "remove": "Remove vote"
        },
        "button": {
          "vote": "Confirm Vote",
          "edit": "Edit vote",
          "remove": "Remove vote"
        },
        "actions": {
          "vote": {
            "confirming": "Confirming vote...",
            "loadingLabel": "Confirm vote in wallet"
          }
        },
        "emissionsInfo": "Your vote directs future liquidity mining emissions starting from the next period on Thursday at 0:00 UTC.",
        "voteForGauge": "{0} for {1}",
        "remainingVotes": "Your remaining votes: {0}",
        "remainingVotesEditing": "Your remaining votes: {0} ({1} current allocation in this pool + {2} unallocated votes)",
        "remainingVotesExceeded": "This exceeds your remaining votes of: {0}",
        "resubmitVote": "Voting power is set at the time of the vote. If you get more veBAL later, resubmit your vote to use your increased power.",
        "voteLockInfo": "Votes are timelocked for 10 days. If you vote now, no edits can be made until {0}.",
        "warnings": {
          "votedTooRecently": {
            "title": "Votes are locked for 10 days",
            "description": "You won't be able to make any edits to this vote allocation for {0}"
          },
          "veBalLockTooShort": {
            "title": "veBAL not locked for 7 days",
            "description": "You must have veBAL locked for more than 7 days to vote on gauges."
          },
          "veBalVoteOverLimitWarning": {
            "title": "You may be wasting your vote: veBAL cap hit",
            "description": "Distributions to veBAL holders of weekly emissions are capped at 10%. Any votes exceeding this amount at Thursday 0:00 UTC will not be counted."
          },
          "lpVoteOverLimitWarning": {
            "title": "You may be wasting your vote",
            "description": "Distributions to LPs of this pool gauge are capped at {0}%. Any votes exceeding this amount at Thursday 0:00 UTC will not be counted."
          }
        },
        "errors": {
          "notEnoughVotes": "Not enough unallocated votes"
        }
      },
      "resubmit": {
        "hint": {
          "title": "Resubmit your votes to utilize your full voting power",
          "description": "Votes on pools are set at the time of your last vote. Since you’ve added new veBAL after your original vote, the additional voting power is not being used."
        },
        "resubmitWarning": "Votes on pools are set at the time of the vote. Since you’ve added new veBAL since your original vote, you have additional voting power which is not being used. To use your full voting power, resubmit your votes."
      }
    },
    "notSupported": {
      "title": "Locking is unavailable on this network",
      "description": "Get veBAL on Ethereum Mainnet"
    },
    "votingTransitionDescription": "Liquidity mining emissions as directed by veBAL holders start on 7 April 2022 at 0:00 UTC! Voting is now live, so get veBAL and vote for pools. Your vote will persist until you edit. Editing can only be done once in 10 days."
  },
  "getVeBAL": {
    "lockableTokens": {
      "title": "Lockable tokens in my wallet",
      "getMoreVeBAL": "Get {0}"
    },
    "howToLock": {
      "title": "How to lock and earn?",
      "steps": {
        "investPart1": "Add liquidity to the",
        "investPart2": "pool.",
        "lock": "Lock-up the resulting LP token ({0}). The longer you lock (1 year max), the more veBAL you get."
      },
      "earn": {
        "boost": "veBAL holders get boosted liquidity mining yields (up to 2.5x) and increased voting power.",
        "voting": "Use this voting power to choose which pool gauges get allocated liquidity mining incentives. Vote on the pools where you have added liquidity to earn more yield."
      }
    },
    "myVeBAL": {
      "title": "My veBAL",
      "lockedEndDate": "Locked until",
      "expiredOn": "Expired on",
      "percentVeBAL": "% of total veBAL"
    },
    "lockForm": {
      "title": "Lock to get veBAL",
      "lockAmount": {
        "title": "How much do you want to lock?"
      },
      "lockEndDate": {
        "title": "Lock until",
        "tooltip": "The longer you lock your stake, the more veBAL voting power you get (up to 4 years). You can extend the lock later. Be careful, locking is not reversible."
      },
      "summary": {
        "receive": {
          "title": "Total voting escrow",
          "tooltip": ""
        },
        "weeklyYield": {
          "title": "Potential weekly staking yield",
          "tooltip": ""
        }
      },
      "lockPeriods": {
        "1w": "1 week",
        "2w": "2 weeks",
        "1m": "1 month",
        "3m": "3 months",
        "6m": "6 months",
        "1y": "1 year"
      }
    },
    "previewModal": {
      "titles": {
        "createLock": {
          "default": "Locking preview",
          "confirmed": "Locking confirmed"
        },
        "extendLock": {
          "default": "Extend lock preview preview",
          "confirmed": "Extend lock preview confirmed"
        },
        "increaseLock": {
          "default": "Increase lock amount",
          "confirmed": "Increase lock amount confirmed"
        }
      },
      "lpTokens": "{0} LP tokens",
      "summary": {
        "title": "Locking summary",
        "totalToLock": "Total to lock",
        "totalAlreadyLocked": "Total already locked",
        "lockEndDate": "Lock-up end date",
        "newLockEndDate": "New lock-up end date",
        "totalVotingEscrow": "Total voting escrow",
        "totalVotingEscrowTooltip": {
          "explainer": "Lock-up end dates can only be on Thursdays at 0:00 UTC. To get as close as possible to a 1 year lock, locking on Thursday is recommended.",
          "table": {
            "lockPeriod": "Lock period",
            "totalVeBAL": "Total veBAL"
          }
        },
        "increasedLockAmount": "Increased lock amount",
        "potentialWeeklyYield": "Potential weekly yield"
      },
      "actions": {
        "approve": {
          "confirming": "Confirming approval...",
          "tooltip": "You must approve {0}.",
          "loadingLabel": "Confirm approval in wallet"
        },
        "createLock": {
          "label": "Confirm lock until {0}",
          "confirming": "Confirming...",
          "tooltip": "Confirm locking.",
          "loadingLabel": "Confirm lock in wallet"
        },
        "extendLock": {
          "label": "Extend lock period",
          "confirming": "Confirming...",
          "tooltip": "Confirm extending lock period.",
          "loadingLabel": "Confirm lock in wallet"
        },
        "increaseLock": {
          "label": "Increase lock amount",
          "confirming": "Confirming...",
          "tooltip": "Confirm increasing lock amount.",
          "loadingLabel": "Confirm lock in wallet"
        }
      },
      "returnToVeBalPage": "Return to veBAL page",
      "firstVeBALReceived": {
        "title": "Vote on pools to direct liquidity incentives",
        "description": "Your voting weight is set at the time of the vote. If you acquire additional veBAL, resubmit your votes to utilize your newly acquired voting power. Voting per pool can only be done once every 10 days."
      }
    },
    "notSupported": {
      "title": "Locking is unavailable on this network",
      "description": "Get veBAL on Ethereum Mainnet"
    }
  },
  "getVeBALToVote": "Get veBAL to vote",
  "unlockVeBAL": {
    "expiredLockableTokens": {
      "title": "Expired locked tokens"
    },
    "unlockForm": {
      "title": "Unlock",
      "lockedAmount": {
        "title": "Available tokens to unlock:"
      }
    },
    "previewModal": {
      "titles": {
        "unlock": {
          "default": "Unlocking preview",
          "confirmed": "Unlocking confirmed"
        }
      },
      "lpTokens": "{0} LP tokens",
      "summary": {
        "title": "Unlocking summary",
        "totalToUnlock": "Total to unlock",
        "totalVotingEscrow": "Total voting escrow",
        "expiredOn": "Expired on"
      },
      "actions": {
        "unlock": {
          "label": "Confirm unlock",
          "confirming": "Confirming...",
          "tooltip": "Confirm unlocking.",
          "loadingLabel": "Confirm unlock in wallet"
        }
      },
      "returnToVeBalPage": "Return to veBAL page"
    },
    "notSupported": {
      "title": "Unlocking is unavailable on this network",
      "description": "Unlock on Ethereum Mainnet"
    }
  },
  "tableShowsBalancerV2Pools": "This table shows only BurrBear V2 pools.",
  "theme": "Theme",
  "yieldAprRewards": {
    "apr": {
      "token": "Token APR",
      "boosted": "Boosted APR",
      "veBAL": "veBAL APR"
    },
    "fiat": {
      "token": "Token rewards",
      "boosted": "Boosted rewards"
    }
  },
  "thereWasAnError": "There was an error",
  "threeMonths": "3M",
  "time": "Time",
  "timeAgo": "{0} ago",
  "token": "Token",
  "tokenInjected": "This token is injected",
  "tokenLists": "Token lists",
  "tokenPills": {
    "hiddenTokens": "+{0} tokens",
    "balanceTooltip": {
      "title": "In your wallet {0}"
    }
  },
  "tokens": "Tokens",
  "tokenSearch": "Token search",
  "tokensLowerCase": "tokens",
  "tokensParen": "Token(s)",
  "tokenPrices": "Token prices",
  "unlistedTokenWarningTitle": "You have added a token that is not included in the BurrBear Tokenlist.",
  "unlistedTokenWarning": "You will not be able to complete pool creation until it's included. To add a token to our Tokenlist please follow our process described",
  "tooltips": {
    "addLiquidity": {
      "priceImpact": "Price impact from adding liquidity results when the value of each token added is not proportional to the weights of the pool. Adding non-proportional amounts causes the internal prices of the pool to change, as if you were swapping tokens. The higher the price impact, the worse price you’ll get to enter your position.",
      "total": "The total value in {0} you’ll be adding into this pool."
    },
    "withdraw": {
      "priceImpact": "Price impact from removing liquidity results when the value of each token removed is not proportional to the weights of the pool. Removing non-proportional amounts causes the internal prices of the pool to change, as if you were swapping tokens. The higher the price impact, the worse price you’ll get for exiting your position.",
      "total": "The total value in {0} you’ll be withdrawing from this pool."
    },
    "veBalApr": {
      "title": "Max locking/veBAL APR",
      "breakdown1": "BAL",
      "breakdown2": "bb-a-USD"
    }
  },
  "total": "Total",
  "totalToMigrate": "Total to migrate",
  "totalAllocated": "Total allocated",
  "totalAPR": "Total APR",
  "totalSupply": "Total supply",
  "totalValue": "Total value",
  "totalValueTo": "Total value to",
  "swapGaslessToggle": {
    "disabledTooltip": {
      "eth": "This option is disabled because gasless swaps only work with ERC-20 tokens. 'ETH' is not an ERC-20 token.",
      "wrapUnwrap": "This option has been disabled because Wrap/Unwrap transactions always require a gas transaction."
    },
    "label": "Swap gasless",
    "tooltip": "‘Swap gasless’ on the BurrBear CoW Protocol for free signature swaps, MEV protection, and the best prices with intelligent swap routing across DeFi exchanges. Turn it off to swap only through BurrBear liquidity pools using gas."
  },
  "swapLiquidity": "Swap liquidity",
  "swapSettled": "Your swap has settled",
  "swapsThroughWeth": "Swaps from ETH route through BurrBear liquidity pools and incur gas fees.",
  "swapSubmissionError": {
    "actionLabel": "Dismiss",
    "title": "An error has occurred"
  },
  "isJoinExitTipDescription": {
    "withApproval": "This swap will be submitted via the Relayer. This uses a little more gas but gets a better price. Requires a free, one-time Batch Relayer signature.",
    "withoutApproval": "This swap will be submitted via the Relayer. This uses a little more gas but gets a better price. "
  },
  "swapSuccess": "Successfully swapped your tokens",
  "swapSummary": {
    "exactIn": {
      "title": "Swap from {0} details",
      "totalAfterFees": "Total expected after fees",
      "totalBeforeFees": "Total to receive before fees",
      "totalWithSlippage": "The least you’ll get at {0} slippage",
      "swapFees": "Swap fees"
    },
    "exactOut": {
      "title": "Swap to {0} details",
      "totalAfterFees": "Total expected to swap after fees",
      "totalBeforeFees": "Total to swap before fees",
      "totalWithSlippage": "The most you’ll send at {0} slippage",
      "swapFees": "Swap fees"
    },
    "gasCosts": "Gas costs",
    "transactionTypesTooltips": {
      "cowswapRelayerApproval": {
        "content": "Your permission is required to use a batch relayer for gas-free, MEV-protected transactions.",
        "title": "Approve swaps on Cowswap protocol"
      },
      "lidoRelayerApproval": {
        "content": "Your permission is required to use the Lido relayer for stETH swaps.",
        "title": "Approve Lido relayer"
      },
      "batchRelayerApproval": {
        "content": "Your permission is required to use the Batch relayer.",
        "title": "Approve Batch relayer"
      },
      "tokenApproval": {
        "content": "Your permission is required to swap this token on BurrBear. Approvals are required once per token, per wallet.",
        "title": "Approve {0}"
      },
      "sign": {
        "content": "Upon your signature, the BurrBear CoW Protocol will find an optimal route amongst other swappers or from liquidity on other DeFi exchanges, with MEV protection.",
        "title": "Sign a free message to swap"
      },
      "swap": {
        "content": "Finalize the transaction by submitting it for inclusion on the blockchain.",
        "title": "Confirm transaction"
      }
    },
    "unwrap": {
      "title": "Summary",
      "totalAfterFees": "Total to receive",
      "totalBeforeFees": "Amount before fees",
      "totalWithSlippage": "Zero slippage to unwrap {0}",
      "swapFees": "Unwrap fees"
    },
    "wrap": {
      "title": "Summary",
      "totalAfterFees": "Total to receive",
      "totalBeforeFees": "Amount before fees",
      "totalWithSlippage": "Zero slippage to wrap {0}",
      "swapFees": "Wrap fees"
    }
  },
  "swapToggle": {
    "wrapEth": "Gas swap—required when wrapping ETH",
    "unwrapEth": "Gas swap—required when unwrapping ETH",
    "fromEth": "Gas swap—required when swapping from ETH",
    "signature": "Signature ‘gasless’ swap—price optimized with MEV protection.",
    "swapGasless": "Swap gasless by signature",
    "swapWithGas": "Swap with a gas fee"
  },
  "transactionAction": {
    "stake": "Stake",
    "unstake": "Unstake",
    "approve": "Approve",
    "claim": "Claim",
    "createPool": "Create pool",
    "drip": "Drip",
    "fundPool": "Fund pool",
    "migratePool": "Migrate pool",
    "invest": "Add liquidity",
    "swap": "Swap",
    "unwrap": "Unwrap",
    "voteForGauge": "Vote",
    "withdraw": "Withdraw",
    "wrap": "Wrap",
    "createLock": "Lock",
    "extendLock": "Extend lock",
    "increaseLock": "Increase lock",
    "unlock": "Unlock",
    "sync": "Sync",
    "userGaugeCheckpoint": "Pool gauge veBAL update",
    "claimSubmission": "Claim submission"
  },
  "transactionDeadline": "Transaction deadline",
  "transactionDeadlineTooltip": "Your swap will expire and not execute if it is pending for more than the selected duration. Only executed transactions incur fees for swaps between ERC-20 tokens.",
  "transactionErrors": {
    "default": {
      "title": "Transaction failed",
      "description": "Please try again later."
    },
    "gasTooLow": {
      "title": "Gas too low",
      "description": "Transaction gas is too low. There is not enough gas to cover minimal cost of the transaction. Try increasing supplied gas."
    },
    "cannotEstGas": {
      "title": "Cannot estimate gas",
      "description": "Transaction may fail or require manual gas limit."
    },
    "slippage": {
      "title": "Transaction failed due to slippage",
      "description": "The market price changed beyond your slippage tolerance due to other swaps processed before yours. Try again or increase your slippage tolerance."
    }
  },
  "transactionPending": "No transactions pending | 1 transaction pending | {n} transactions pending",
  "transactionStatus": {
    "cancelled": "Cancelled",
    "cancelling": "Cancelling",
    "expired": "Expired",
    "failed": "Failed",
    "fulfilled": "Confirmed",
    "pending": "Pending"
  },
  "transactionSummary": {
    "stake": "{amount} in {pool}",
    "unstake": "{amount} from {pool}",
    "approveForInvesting": "Approve {0} for adding liquidity",
    "approveForLocking": "Approve {0} for locking",
    "approveForStaking": "Approve {0} for staking",
    "approveForSwapping": "Approve {0} for swapping",
    "approveForUnwrapping": "Approve {0} for unwrapping",
    "unapprove": "Unapprove {0}",
    "extendLock": "Lock until {0}",
    "unlock": "Unlock {0}",
    "approveRelayer": "Approve {0} relayer",
    "approveMigration": "Approve {0} migration",
    "createPool": "Create pool",
    "fundPool": "Fund pool",
    "investInPool": "{0} in {1}",
    "withdrawFromPool": "{0} from {1}",
    "withdrawFromBalance": "{0} from Vault balance",
    "withdrawToBalance": "{0} to Vault balance",
    "migratePool": "{0} {1} to {2}",
    "wrapUnwrap": "{0} {1} to {2}",
    "tooltips": {
      "approveForInvesting": "You must approve {0} to add liquidity for this token on BurrBear. Approvals are required once per token, per wallet.",
      "approveForLocking": "You must approve {0} to lock this token. Approvals are required once per token, per wallet.",
      "approveForStaking": "You must approve {0} to stake this token on BurrBear. Approvals are required once per token, per wallet.",
      "approveForSwapping": "You must approve {0} to swap this token on BurrBear. Approvals are required once per token, per wallet.",
      "approveForUnwrapping": "You must approve {0} to unwrap this token. Approvals are required once per token, per wallet.",
      "unapprove": "You must unapprove {0} before a new approval value can be set"
    }
  },
  "transactionType": "Transaction type",
  "unallocated": "Unallocated",
  "unavailableOnNetwork": "Sorry! BurrBear is not available on this network",
  "unavailableOnNetworkWithName": "Sorry! BurrBear is not available on {0}",
  "unsupportedNetwork": "Unsupported network",
  "unknown": "Unknown",
  "unknownPoolType": "Unknown pool type",
  "unknownTokenPrice": "Unknown token price",
  "unlock": "Unlock",
  "unlocked": "Unlocked",
  "unlocking": "Unlocking",
  "unstake": "Unstake",
  "unstaked": "Unstaked",
  "restake": "Restake",
  "restaked": "Restaked",
  "unwrap": "Unwrap",
  "usdValue": "USD Value",
  "useOf": "use of",
  "useSignatures": "Use signatures",
  "useSignaturesTooltip": "It's recommended to turn on signatures for gas-free transactions. However, if your wallet doesn't support the signing of signatures, you can turn it off.",
  "v1": "V1",
  "v2": "V2",
  "value": "Value",
  "vault": "Vault",
  "vebal": "veBAL",
  "veBalProtocolLiquidity": "veBAL protocol liquidity",
  "vestVote": "Vest+Vote",
  "viewAllAssets": "View all assets ({0})",
  "viewAndManangeOnElement": "View and manage all pools on Element.fi",
  "viewClaims": "View & claim rewards",
  "viewFullWallet": "View full wallet",
  "viewLess": "View less",
  "viewOnElement": "View on Element.fi",
  "viewPool": "View pool",
  "viewTransaction": "View transaction",
  "volume24h": "Volume (24{0})",
  "volume24hShort": "Vol (24h)",
  "volumeTime": "Volume ({0})",
  "vote": "Vote",
  "wallet": "Wallet",
  "wantToStartOverInstead": "Want to start over instead?",
  "warning": "Warning",
  "week": "Week",
  "weekAbbrev": "w",
  "weight": "Weight",
  "weightedPool": "Generalized pool",
  "whichPools": "Which liquidity pools should be used when you make a swap.",
  "withdraw": {
    "tabs": {
      "poolTokens": "Proportional pool tokens",
      "singleToken": "Single token"
    },
    "label": "Withdraw",
    "tooltips": {
      "priceImpact": "Withdrawing custom amounts causes the internal prices of the pool to change, as if you were swapping tokens. The higher the price impact the more you'll spend in swap fees."
    },
    "preview": {
      "loadingLabel": {
        "withdraw": "Confirm withdrawal in wallet",
        "building": "Building transaction"
      },
      "titles": {
        "confirmed": "Withdrawal confirmed",
        "default": "Withdrawal preview"
      },
      "tooltips": {
        "withdrawStep": "Confirm withdrawal from this pool"
      }
    },
    "errors": {
      "SINGLE_ASSET_WITHDRAWAL_MIN_BPT_LIMIT": "You can only withdraw 30% of the pool's value in single asset withdrawals"
    }
  },
  "youProvide": "You provide",
  "youReceive": "You receive",
  "withdrawal": "Withdrawal",
  "withdrawFromPool": "Withdraw from pool",
  "withdrawalSettled": "Your withdrawal has settled",
  "withdrawalSuccess": "Your liquidity has been withdrawn from this pool, and the underlying tokens are now in your wallet.",
  "withdrawFee": "Withdraw fee",
  "withdrawing": "withdrawing",
  "withdrawWarning": "Withdrawing single asset amounts causes the internal prices of the pool to change, as if you were swapping tokens. The higher the price impact the more you'll spend in swap fees.",
  "wrap": "Wrap",
  "wrapInstruction": "Wrap your {0} to {1}",
  "wrapStEthTooltip": "This pool requires Wrapped Liquid Staked ETH 2.0 (wstETH). To maximize your liquidity provision, you could wrap some additional stETH.",
  "yearAbbrev": "y",
  "yieldEarnings": "Yield earnings",
  "youDidIt": "You did it!",
  "your": "Your",
  "yourTransactions": "Your transactions in this pool",
  "yourBalanceInPool": "Your balance in this pool",
  "chooseAPair": "Please select a valid pair",
  "youCanAdd": "You can add",
  "poolChart": {
    "defaultTitle": {
      "tvl": "Current TVL"
    },
    "period": {
      "all": "All time",
      "days": "{0} days",
      "title": "Time period"
    },
    "tabs": {
      "fees": "Fees",
      "tvl": "TVL",
      "volume": "Volume"
    },
    "fxPoolWarning": "Under certain circumstances, FX Pools offer rebates to traders, i.e. \"negative swap fees\"."
  },
  "yes": "Yes",
  "none": "None",
  "poolAttrs": {
    "feesFixed": "fixed forever",
    "feesEditableGovernance": "this may be edited via Governance",
    "feesEditableOwner": "this may be edited by the Pool Owner",
    "immutable": "This pool is immutable. No attributes can ever be changed.",
    "immutableFeesEditableByGovernance": "The attributes of this pool are immutable, except for swap fees which can be edited via Governance.",
    "immutableFeesEditableByOwner": "The attributes of this pool are immutable, except for swap fees which can be edited by the Pool Owner."
  },
  "myWalletCard": {
    "title": {
      "poolTokens": "Pool tokens (lowest price impact)",
      "otherTokens": "Other tokens (higher price impact)"
    }
  },
  "poolActivity": "Pool activity",
  "legacyClaims": "Legacy claims",
  "inputLabels": {
    "tokenOut": "Token out",
    "tokenIn": "Token in"
  },
  "poolRisks": {
    "title": "Pool risks"
  }
}
